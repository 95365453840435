<template>
	<div class="main-wrapper" data-app>
		<!-- Banner -->
		<banner :title="''" :items="bannerImages" :subtitle="'XPO Magazine'"></banner>

		<div class="content">
			<div class="container">
				<div class="row">
					<v-breadcrumbs
						style="color: black"
						:items="itemsMenu"
						divider="-"
					></v-breadcrumbs>
				</div>
				<div class="row">
					<div class="col-lg-6">
						<div class="text-center">
							<animation-text-one />
							<p style="text-align: justify; max-width: 500px" class="mt-4">
								Con más de 10 años de trayectoria, XPO Magazine es pionera entre las
								publicaciones dedicadas al mercadeo, la publicidad y la promoción de las
								Franquicias en Venezuela.
							</p>
							<p style="text-align: justify; max-width: 500px">
								XPO Magazine ofrece una serie de servicios de valor para los anunciantes
								que quieren promocionar su marca y recibir contactos de inversionistas
								interesados. Nos apoyamos en eventos y alianzas con medios audiovisuales
								masivos como radio y TV.
							</p>
							<p style="text-align: justify; max-width: 500px">
								Aquí encontrarás las Ediciones publicadas hasta el momento.
							</p>
						</div>
					</div>
					<div class="row col-lg-6">
						<div class="col-md-6" style="padding: auto 0px !important">
							<div class="pricing-container">
								<!-- Plan #3 -->
								<div class="plan featured" style="transform: none">
									<div class="listing-badge">
										<span class="featured"><strong>PRE VENTA</strong></span>
									</div>

									<div class="plan-price" style="padding-bottom: 30px">
										<h3>X Edición Aniversario - Agosto 2021</h3>
									</div>
									<div class="plan-features" style="padding: 20px">
										<v-row style="margin: 10px auto"
											><span style="padding: 0px"
												><strong>Temas principales:</strong> <br />
												Marcas Venezolanas (Historia, trayectoria y futuro), Delivery,
												Comercio Electrónico y Home Office</span
											></v-row
										>

										<a href="/#contacto" class="btn btn-primary btn-radius mt-4"
											>Anunciar</a
										>
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-6 col-md-6" style="">
							<img
								src="https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/448b33b9aa586ffb350f250c57759cb4.jpg"
								style="width: 100%"
								alt=""
							/>
							<img
								src="https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/751c3de77b524512f8cd8ec6dd902235.jpg"
								style="width: 100%"
								class="pt-2"
								alt=""
							/>
						</div>
					</div>
				</div>
				<div class="pricing-n-plan mt-4">
					<div class="row">
						<div class="col-lg-4 mb-4">
							<v-card class="mx-auto" max-width="500">
								<v-toolbar color="cyan" style="background-color: #003a56">
									<v-app-bar-nav-icon style="color: #fff"></v-app-bar-nav-icon>
									<v-toolbar-title style="color: #fff">Ediciones</v-toolbar-title>
									<v-spacer></v-spacer>
								</v-toolbar>

								<v-list>
									<v-list-group
										v-for="item in items"
										:key="item.title"
										v-model="item.active"
										:prepend-icon="item.action"
										no-action
									>
										<template v-slot:activator>
											<v-list-item-content>
												<v-list-item-title v-text="item.title"></v-list-item-title>
											</v-list-item-content>
										</template>

										<v-list-item
											v-for="child in item.items"
											:key="child.title"
											link
											@click="selectMagazine(child.file)"
										>
											<v-list-item-content>
												<v-list-item-title v-text="child.title"></v-list-item-title>
											</v-list-item-content>
										</v-list-item>
									</v-list-group>
								</v-list>
							</v-card>
						</div>
						<div class="col-lg-7 mb-4">
							<iframe
								:src="this.edition"
								style="width: 750px; height: 700px"
								frameborder="0"
							>
							</iframe>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import AdsSection2 from 'Components/globalFrontendComponents/AdsSection2'
	import Header2 from 'Components/globalFrontendComponents/Header2'
	import AnimationText2 from 'Components/globalFrontendComponents/AnimationText2'

	export default {
		components: {
			AnimationTextOne: AnimationText2,
			banner: Header2,
			AdsSection2,
		},
		data() {
			return {
				date: new Date().toISOString(),
				currentPage: 0,
				pageCount: 0,
				dialog: false,
				selectPage: 1,
				edition:
					'https://docs.google.com/gview?url=https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/68d98ac4dcb43419f64dfb4cef40a270.pdf&embedded=true',
				itemsMenu: [
					{
						text: 'Inicio',
						disabled: false,
						href: '/',
					},
					{
						text: 'XPO Magazine',
						disabled: true,
						href: '',
					},
				],
				bannerImages: [
					{
						id: 1,
						route:
							'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/5ef112fd5fb6a12cc682bb7b0e60b9ab.jpg',
						bannerMobile:
							'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/e7dcbe7cf3a4cfdab2aa71f7d9f80719.jpg',
					},
					{
						id: 2,
						route:
							'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/5ef112fd5fb6a12cc682bb7b0e60b9ab.jpg',
						bannerMobile:
							'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/e7dcbe7cf3a4cfdab2aa71f7d9f80719.jpg',
					},
				],
				items: [
					{
						action: 'mdi-book',
						active: true,
						items: [
							{
								title: 'XI Edición - Septiembre',
								active: true,
								file: 'a46528cd38848e03b1e2786a6e8d1744.pdf',
							},
						],
						title: ' 2022',
					},
					{
						action: 'mdi-book',
						active: true,
						items: [
							{
								title: 'X Edición - Agosto',
								active: true,
								file: '68d98ac4dcb43419f64dfb4cef40a270.pdf',
							},
							{
								title: 'IX Edición - Junio',
								active: true,
								file: 'junio2021.pdf',
							},
							{
								title: 'VIII Edición - Mayo',
								file: 'abril2021.pdf',
							},
						],
						title: ' 2021',
					},
					{
						action: 'mdi-book',
						items: [
							{
								title: 'VII Edición - Diciembre',
								file: 'diciembre2020.pdf',
							},
							{
								title: 'VI Edición - Septiembre',
								file: 'septiembre2020.pdf',
							},
						],
						title: ' 2020',
					},
					{
						action: 'mdi-book',
						items: [
							{
								title: 'V Edición - Diciembre',
								file: 'diciembre2019.pdf',
							},
							{
								title: 'IV Edición - Octubre',
								file: 'octubre2019.pdf',
							},
							{
								title: 'III Edición - Julio',
								file: 'julio2019.pdf',
							},
							{
								title: 'II Edición - Abril',
								file: 'abril2019.pdf',
							},
						],
						title: ' 2019',
					},
					{
						action: 'mdi-book',
						items: [
							{
								title: 'I Edición - Diciembre',
								file: 'diciembre2018.pdf',
							},
						],
						title: ' 2018',
					},
				],
			}
		},
		methods: {
			nextPage() {
				this.selectPage = this.selectPage + 1
				if (this.selectPage > this.pageCount) {
					this.selectPage = this.pageCount
				}
			},
			previousPage() {
				this.selectPage = this.selectPage - 1
				if (this.selectPage < 1) {
					this.selectPage = 1
				}
			},
			async selectMagazine(file) {
				this.edition =
					'https://docs.google.com/gview?url=https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/' +
					file +
					'&embedded=true'
			},
		},
		metaInfo() {
			return {
				title: `Magazine - XPOFranchise`,
				meta: [
					{
						name: 'description',
						content:
							'Revista Digital que ofrece información general sobre el sector Franquicia y sus marcas en Latinoamérica',
					},
					{
						property: 'og:title',
						content: `Magazine - XPOFranchise`,
					},
					{
						property: 'og:description',
						content:
							'Hub de Franquicias y Negocios, Marcas, Comercialización, Asesoría para Inversionistas, Software de gestión, Proveedores. Guía Internacional de Franquicias.',
					},
					{
						property: 'og:image',
						content:
							'public/static/xpo_isotipo--white.svg',
					},
					{
						property: 'og:url',
						content: process.env.VUE_APP_WEBSITE + this.$route.fullPath,
					},
					{
						property: 'og:updated_time',
						content: this.date,
					},
					{
						name: 'twitter:title',
						content: `Magazine - XPOFranchise`,
					},
					{
						name: 'twitter:description',
						content:
							'Hub de Franquicias y Negocios, Marcas, Comercialización, Asesoría para Inversionistas, Software de gestión, Proveedores. Guía Internacional de Franquicias.',
					},
					{
						name: 'twitter:image',
						content:
							'public/static/xpo_isotipo--white.svg',
					},
					{
						name: 'twitter:url',
						content: process.env.VUE_APP_WEBSITE + this.$route.fullPath,
					},
					{
						name: 'og:updated_time',
						content: this.date,
					},
				],
			}
		},
	}
</script>

<style>
	.left {
		float: left;
		margin-top: 550px;
		z-index: 2500;
	}

	.left2 {
		position: fixed;
		width: 60px;
		height: 60px;
		bottom: 300px;
		z-index: 2500;
	}
	.right2 {
		position: fixed;
		width: 60px;
		height: 60px;
		bottom: 300px;
		right: 40px;
		z-index: 2500;
	}

	.right {
		float: right;
		margin-top: 550px;
		z-index: 2500;
	}
	.center {
		text-align: center;
	}
</style>
